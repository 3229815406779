import React, { useEffect, useRef } from 'react';
import loadable from '@wix/yoshi-flow-editor/loadable';
import {
  useEnvironment,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { withSlotsPlaceholders } from '@wix/widget-plugins-ooi';
import { useSettings } from '@wix/tpa-settings/react';
import { TimezoneType } from '@wix/bookings-uou-types';

import { SchedulingLocationViewModel } from '../../../service-page-view-model/scheduling-location-view-model/schedulingLocationViewModel';
import { SchedulingTimezoneViewModel } from '../../../service-page-view-model/shceduling-timezone-view-model/schedulingTimezoneViewModel';
import { SchedulingSectionViewModel } from '../../../service-page-view-model/scheduling-section-view-model/schedulingSectionViewModel';
import { ServicePageViewModel } from '../../../service-page-view-model/servicePageViewModel';

import {
  BookingsServicesPageViewParams,
  ISection,
  SectionTypes,
  SidebarPosition,
} from '../types';
import { BookButtonViewModelProvider } from './useBookButtonViewModel';
import { WidgetEventsProvider } from './useWidgetEvents';
import { classes, st } from './Widget.st.css';

import UserMessage from './UserMessage/UserMessage';
import TitleAndTagline from './TitleAndTagline';
import settingsParams from '../settingsParams';
import Description from './Description';
import Sidebar from './Sidebar/Sidebar';
import EmptyState from './EmptyState';
import Header from './Header/Header';
import Policy from './Policy/Policy';
import Details from './Details';
import Contact from './Contact';
import Plugin from './Plugin';
import Body from './Body/Body';
import { useSizeListener } from './useSizeListener';
import { DimensionsProvider } from './useDimensions';
import { useWidgetSections } from './useWidgetSections';
import Section from './Section';

const Gallery = loadable(
  () => import(/* webpackChunkName: "Gallery" */ './Gallery/Gallery'),
);
const Scheduling = loadable(
  () => import(/* webpackChunkName: "Scheduling" */ './Scheduling/Scheduling'),
);

import { useVisibilityExperiment } from '../hooks/useVisibilityExperiment';
import { useVisibilityCheck } from '../hooks/useVisibilityCheck';

export interface ControllerProps {
  viewModel?: ServicePageViewModel;
  scheduleViewModel?: SchedulingSectionViewModel;
  locationViewModel?: SchedulingLocationViewModel;
  timezoneViewModel?: SchedulingTimezoneViewModel;
  changeTimezoneCallback?: (timezoneType: TimezoneType) => void;
  changeLocationCallback?: (locationId: string) => void;
  navigateToCalendar: (referralInfo?: string) => Promise<void>;
  reportPageLoaded: (params: BookingsServicesPageViewParams) => void;
  userMessage?: {
    shouldDisplayMessage: boolean;
    closeMessage: () => void;
  };
  isRedirectingToCalendar?: boolean;
}

const getSectionComponentByType = (
  section: ISection,
  viewModel: ServicePageViewModel,
  schedule?: SchedulingSectionViewModel,
  locationViewModel?: SchedulingLocationViewModel,
  timezoneViewModel?: SchedulingTimezoneViewModel,
) => {
  switch (section.type) {
    case SectionTypes.TITLE_TAGLINE:
      return (
        <TitleAndTagline
          key={section.type}
          section={section}
          viewModel={viewModel.titleAndTagline}
        />
      );
    case SectionTypes.SCHEDULING:
      if (viewModel.showSchedulingSection) {
        return (
          <Section
            sectionType={SectionTypes.SCHEDULING}
            data-hook="scheduling-section"
            withBookButton={schedule?.isBookable && section.bookButton}
          >
            <Scheduling
              key={section.type}
              schedule={schedule}
              locationViewModel={locationViewModel}
              timezoneViewModel={timezoneViewModel}
            />
          </Section>
        );
      }
      break;
    case SectionTypes.POLICY:
      return <Policy key={section.type} viewModel={viewModel.policySection} />;
    case SectionTypes.DETAILS:
      return (
        <Details
          key={section.type}
          section={section}
          viewModel={viewModel.detailsSection}
        />
      );
    case SectionTypes.DESCRIPTION:
      return (
        <Description
          key={section.type}
          section={section}
          viewModel={viewModel.descriptionSection}
        />
      );
    case SectionTypes.CONTACT:
      return (
        <Contact
          key={section.type}
          section={section}
          viewModel={viewModel.contactSection}
        />
      );
    case SectionTypes.GALLERY:
      return viewModel.gallerySection.items.length ? (
        <Section sectionType={SectionTypes.GALLERY}>
          <Gallery key={section.type} viewModel={viewModel.gallerySection} />
        </Section>
      ) : null;
    case SectionTypes.PLUGIN:
      return <Plugin key={section.type} />;
    default:
      return null;
  }
};

export const Widget: React.FC<WidgetProps<ControllerProps>> = ({
  viewModel,
  scheduleViewModel,
  locationViewModel,
  timezoneViewModel,
  changeTimezoneCallback,
  changeLocationCallback,
  navigateToCalendar,
  reportPageLoaded,
  userMessage,
  isRedirectingToCalendar,
}) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const widgetRef = useRef<HTMLDivElement>(null);
  const widgetBoundings = useSizeListener(widgetRef);
  const sections = useWidgetSections();
  const { addVisibilityClass } = useVisibilityExperiment();
  const { shouldBeVisible } = useVisibilityCheck();

  const getSectionByType = (sectionType: SectionTypes): ISection => {
    return sections.find(({ type }) => type === sectionType) as ISection;
  };

  const isSectionVisible = (section: ISection): boolean => {
    return (
      section.visible &&
      (!shouldBeVisible('sidebarVisibility') ||
        section.type !== settings.get(settingsParams.sidebarSection))
    );
  };

  const shouldShowOnlyImage =
    shouldBeVisible('headerImageVisibility') &&
    !shouldBeVisible('headerBookButtonVisibility') &&
    !shouldBeVisible('headerTitleVisibility');
  const isServiceWithoutImage = !viewModel?.header?.image;
  const shouldShowHeader =
    shouldBeVisible('headerVisibility') &&
    !(shouldShowOnlyImage && isServiceWithoutImage);

  const renderBody = (widgetViewModel) => (
    <Body viewModel={widgetViewModel.body}>
      {sections.map((section) =>
        isSectionVisible(section)
          ? getSectionComponentByType(
              section,
              widgetViewModel,
              scheduleViewModel,
              locationViewModel,
              timezoneViewModel,
            )
          : null,
      )}
    </Body>
  );

  const renderSideBar = (widgetViewModel) =>
    shouldBeVisible('sidebarVisibility') ? (
      <Sidebar
        sectionType={settings.get(settingsParams.sidebarSection)}
        className={addVisibilityClass(
          '',
          classes.sidebarVisibility,
          'sidebarVisibility',
        )}
      >
        {getSectionComponentByType(
          getSectionByType(settings.get(settingsParams.sidebarSection)),
          widgetViewModel,
          scheduleViewModel,
          locationViewModel,
          timezoneViewModel,
        )}
      </Sidebar>
    ) : null;

  useEffect(() => {
    reportPageLoaded?.({
      emptyState: !viewModel,
      message_to_user: viewModel?.body.messageType,
      platform: isMobile ? 'mobile' : '',
      containers: viewModel
        ? `body${shouldShowHeader ? ',header' : ''}${
            shouldBeVisible('sidebarVisibility') ? ',sidebar' : ''
          }`
        : '',
      sections: viewModel ? JSON.stringify(sections) : '',
    });
  }, [reportPageLoaded]);

  const isSideBarOnTheRight = () =>
    settings.get(settingsParams.sidebarPosition) === SidebarPosition.RIGHT;
  const isNoHeaderAndTitleInSideBar = () => {
    const isSideBarVisible = shouldBeVisible('sidebarVisibility');
    const isSideBarSectionIsTitleAndTagline =
      settings.get(settingsParams.sidebarSection) ===
      SectionTypes.TITLE_TAGLINE;
    const isSideBarTitleVisible = shouldBeVisible('serviceTitleVisibility');
    return (
      !shouldShowHeader &&
      isSideBarVisible &&
      isSideBarSectionIsTitleAndTagline &&
      isSideBarTitleVisible
    );
  };
  const inverseRowBySideBarPositionAndNoHeaderLogic =
    (isNoHeaderAndTitleInSideBar() && isSideBarOnTheRight()) ||
    (!isNoHeaderAndTitleInSideBar() && !isSideBarOnTheRight());

  return (
    <WidgetEventsProvider
      value={{
        changeTimezoneCallback,
        changeLocationCallback,
        navigateToCalendar,
      }}
    >
      <BookButtonViewModelProvider value={viewModel?.button}>
        <DimensionsProvider
          value={{
            width: widgetBoundings.width,
            height: widgetBoundings.height,
          }}
        >
          <div
            className={st(classes.root, {
              sidebarPosition: settings.get(settingsParams.sidebarPosition),
              isMobile,
              isNoHeaderAndTitleInSideBar:
                inverseRowBySideBarPositionAndNoHeaderLogic,
              isNavigating: !!viewModel?.button?.navigationInitiatedBy,
            })}
            ref={widgetRef}
          >
            {viewModel ? (
              <>
                <UserMessage
                  isOpen={userMessage?.shouldDisplayMessage || false}
                  onRequestClose={userMessage?.closeMessage || (() => {})}
                  isMobile={isMobile}
                  message={t('app.widget.uou-messages.non.premium.message')}
                  okLabel={t('app.widget.uou-messages.non.premium.approve')}
                  isRTL={isRTL}
                />
                <div
                  className={classes.root}
                  data-hook="booking-service-page-wrapper"
                >
                  {shouldShowHeader && (
                    <Header
                      viewModel={viewModel.header}
                      className={addVisibilityClass(
                        '',
                        classes.headerVisibility,
                        'headerVisibility',
                      )}
                    />
                  )}
                  <div className={classes.dynamicWrapper}>
                    {isNoHeaderAndTitleInSideBar() ? (
                      <>
                        {renderSideBar(viewModel)}
                        {renderBody(viewModel)}
                      </>
                    ) : (
                      <>
                        {renderBody(viewModel)}
                        {renderSideBar(viewModel)}
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <EmptyState isRedirectingToCalendar={isRedirectingToCalendar} />
            )}
          </div>
        </DimensionsProvider>
      </BookButtonViewModelProvider>
    </WidgetEventsProvider>
  );
};

export default withSlotsPlaceholders(Widget);
